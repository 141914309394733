import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  params: {}, data: ''
};
@Injectable({
  providedIn: 'root'
})
export class AppService {
  ip:any = "103.170.110.4"
  constructor(private http:HttpClient) { }

  register (data): Observable<any> {
    // httpOptions.params= query;
    return this.http.post<any>('http://'+this.ip+'/carry/public/index.php/api/register',data)
      .pipe(
        catchError(this.handleError('getList', []))
      );
  }

  

  sendNotification(body){
    let options = new HttpHeaders().set('Content-Type','application/json');
    return this.http.post("https://fcm.googleapis.com/fcm/send",body,{
      headers: options.set('Authorization', 'key=AIzaSyBKP-AjrX-sIndk2rH19AybApvhc7YgalI'),
    }).pipe(
      tap((data: any) => console.log(`added data w/ id=${data.id}`)),
      catchError(this.handleError<any>('addData'))
    );
  }

  private extractData(res: any) {
    let body = res;
    return body || { };
  }
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  
}
