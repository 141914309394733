<!-- Navigation -->
<div class="header">
    <header class="full-width">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mainNavCol">
                    <!-- logo -->
                    <div class="logo mainNavCol">
                        <a routerLink="/">
                            <img src="assets/img/logo.png" class="img-fluid" alt="Logo">
                        </a>
                    </div>
                    <!-- logo -->
                    <div class="main-search mainNavCol">
                        <form class="main-search search-form full-width">
                            <div class="row">
                                <!-- location picker -->
                                <div class="col-lg-6 col-md-5">
                                    <a href="javascript:void(0)" class="delivery-add p-relative"> <span class="icon"><i
                                                class="fas fa-map-marker-alt"></i></span>
                                        <span class="address">Brooklyn, NY</span>
                                    </a>
                                    <div class="location-picker">
                                        <input type="text" class="form-control" placeholder="Enter a new address">
                                    </div>
                                </div>
                                <!-- location picker -->
                                <!-- search -->
                                <div class="col-lg-6 col-md-7">
                                    <div class="search-box padding-10">
                                        <input type="text" class="form-control" placeholder="Veggies, Grocery, Restaurants">
                                    </div>
                                </div>
                                <!-- search -->
                            </div>
                        </form>
                    </div>
                    <div class="right-side fw-700 mainNavCol">
                        <!-- <div class="gem-points">
                            <a href="javascript:void(0)"> <i class="fas fa-concierge-bell"></i>
                                <span>Order Now</span>
                            </a>
                        </div> -->
                        <div class="catring parent-megamenu">
                            <a href="javascript:void(0)"> <span>Pages <i class="fas fa-caret-down"></i></span>
                                <i class="fas fa-bars"></i>
                            </a>
                            <div class="megamenu">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-5">
                                                <div class="ex-collection-box h-100">
                                                    <a href="#">
                                                        <img src="assets/img/nav-1.jpg"
                                                            class="img-fluid full-width h-100" alt="image">
                                                    </a>
                                                    <div class="category-type overlay padding-15"> <a
                                                            routerLink="/restaurant" class="category-btn">Top rated</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8 col-md-7">
                                                <div class="row">
                                                    <div class="col-lg-3 col-sm-6">
                                                        <div class="menu-style">
                                                            <div class="menu-title">
                                                                <h6 class="cat-name"><a href="#"
                                                                        class="text-light-black">Home Pages</a></h6>
                                                            </div>
                                                            <ul>
                                                                <li><a routerLink="/" class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Landing
                                                                        Page</a>
                                                                </li>
                                                                <li><a routerLink="/homepage1"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                        Page 1</a>
                                                                </li>
                                                                <li><a routerLink="/homepage2"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                        Page 2</a>
                                                                </li>
                                                                <li><a routerLink="/homepage3"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                        Page 3</a>
                                                                </li>
                                                                <li><a routerLink="/homepage4"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                        Page 4</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-sm-6">
                                                        <div class="menu-style">
                                                            <div class="menu-title">
                                                                <h6 class="cat-name"><a href="#"
                                                                        class="text-light-black">Inner Pages</a></h6>
                                                            </div>
                                                            <ul>
                                                                <li><a routerLink="/blog"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Blog
                                                                        Grid View</a>
                                                                </li>
                                                                <li><a routerLink="/blog-style-2"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Blog
                                                                        Grid View 2</a>
                                                                </li>
                                                                <li><a routerLink="/blog-details"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Blog
                                                                        Details</a>
                                                                </li>
                                                                <li><a routerLink="/ex-deals"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Ex
                                                                        Deals</a>
                                                                </li>
                                                                <li><a routerLink="/about"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">About
                                                                        Us</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-sm-6">
                                                        <div class="menu-style">
                                                            <div class="menu-title">
                                                                <h6 class="cat-name"><a href="#"
                                                                        class="text-light-black">Related Pages</a></h6>
                                                            </div>
                                                            <ul>
                                                                <li><a routerLink="/restaurant"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Restaurant</a>
                                                                <li><a routerLink="/restaurant-style-1"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Restaurant
                                                                        1</a>
                                                                </li>
                                                                <li><a routerLink="/restaurant-style-2"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Restaurant
                                                                        2</a>
                                                                </li>
                                                                <li><a routerLink="/add-restaurant"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Add
                                                                        Restaurant</a>
                                                                </li>
                                                                <li><a routerLink="/listview"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">List
                                                                        View</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-sm-6">
                                                        <div class="menu-style">
                                                            <div class="menu-title">
                                                                <h6 class="cat-name"><a href="#"
                                                                        class="text-light-black">Additional Pages</a>
                                                                </h6>
                                                            </div>
                                                            <ul>
                                                                <li><a routerLink="/login"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Login
                                                                        & Signup</a>
                                                                </li>
                                                                <li><a routerLink="/contact"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Contact</a>
                                                                </li>
                                                                <li><a routerLink="/checkout"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Checkout</a>
                                                                </li>
                                                                <li><a routerLink="/orderdetails"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Order
                                                                        Details</a>
                                                                </li>
                                                                <li><a routerLink="/geolocator"
                                                                        class="text-light-white fw-500"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Geo
                                                                        Locator</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- mobile search -->
                        <div class="mobile-search">
                            <a href="#" data-toggle="modal" data-target="#search-box"> <i class="fas fa-search"></i>
                            </a>
                        </div>
                        <!-- mobile search -->

                        <!-- user account -->
                        <div class="user-details p-relative" *ngIf="!isLoggedin">
                            <a href="javascript:void(0)" class="text-light-white fw-500">
                                <img src="assets/img/user-1.png" class="rounded-circle" alt="userimg"> <span>Login</span>
                            </a>
                            <div class="user-dropdown">
                                <div class="user-footer"> <span class="text-light-black">Not Logged In?</span> <a
                                    routerLink="/login"
                                    class="text-light-white fw-500"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Sign In</a>
                                </div>
                                <div class="user-footer"> <span class="text-light-black"></span> <a
                                    routerLink="/register"
                                    class="text-light-white fw-500"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Sign Up</a>
                                </div>
                            </div>
                        </div>
                        <!-- user account -->
                        <div class="user-details p-relative" *ngIf="isLoggedin">
                            <a href="javascript:void(0)" class="text-light-white fw-500">
                                <img src="assets/img/user-1.png" class="rounded-circle" alt="userimg"> <span>Hi,
                                    {{userData.user.name}}</span>
                            </a>
                            <div class="user-dropdown">
                                <ul>
                                    <li>
                                        <a routerLink="/orders/past">
                                            <div class="icon"><i class="flaticon-rewind"></i>
                                            </div> <span class="details">Past Orders</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/orders/upcoming">
                                            <div class="icon"><i class="flaticon-takeaway"></i>
                                            </div> <span class="details">Upcoming Orders</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <div class="icon"><i class="flaticon-breadbox"></i>
                                            </div> <span class="details">Saved</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <div class="icon"><i class="flaticon-gift"></i>
                                            </div> <span class="details">Gift cards</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <div class="icon"><i class="flaticon-refer"></i>
                                            </div> <span class="details">Refer a friend</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <div class="icon"><i class="flaticon-diamond"></i>
                                            </div> <span class="details">Perks</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <div class="icon"><i class="flaticon-user"></i>
                                            </div> <span class="details">Account</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)">
                                            <div class="icon"><i class="flaticon-board-games-with-roles"></i>
                                            </div> <span class="details">Help</span>
                                        </a>
                                    </li>
                                </ul>
                                <div class="user-footer"> <span class="text-light-black"></span> <button
                                        (click)="logingOutUser();">Sign Out</button>
                                </div>
                            </div>
                        </div>
                        <!-- mobile search -->
                        <!-- user notification -->
                        <div class="cart-btn notification-btn">
                            <a href="javascript:void(0)" class="text-light-green fw-700"> <i class="fas fa-bell"></i>
                                <span class="user-alert-notification"></span>
                            </a>
                            <div class="notification-dropdown">
                                <div class="product-detail">
                                    <a href="javascript:void(0)">
                                        <div class="img-box">
                                            <img src="assets/img/shop-1.png" class="rounded" alt="image">
                                        </div>
                                        <div class="product-about">
                                            <p class="text-light-black">Lil Johnny’s</p>
                                            <p class="text-light-white">Spicy Maxican Grill</p>
                                        </div>
                                    </a>
                                </div>
                                <div class="rating-box">
                                    <p class="text-light-black">How was your last order ?.</p> <span
                                        class="text-dark-white"><i class="fas fa-star"></i></span>
                                    <span class="text-dark-white"><i class="fas fa-star"></i></span>
                                    <span class="text-dark-white"><i class="fas fa-star"></i></span>
                                    <span class="text-dark-white"><i class="fas fa-star"></i></span>
                                    <span class="text-dark-white"><i class="fas fa-star"></i></span>
                                    <cite class="text-light-white">Ordered 2 days ago</cite>
                                </div>
                            </div>
                        </div>
                        <!-- user notification -->
                        <!-- user cart -->
                        <div class="cart-btn cart-dropdown">
                            <a href="javascript:void(0)" class="text-light-green fw-700"> <i
                                    class="fas fa-shopping-bag"></i>
                                <span class="user-alert-cart">3</span>
                            </a>
                            <div class="cart-detail-box">
                                <div class="card">
                                    <div class="card-header padding-15">Your Order</div>
                                    <div class="card-body no-padding">
                                        <div class="cat-product-box">
                                            <div class="cat-product">
                                                <div class="cat-name">
                                                    <a href="javascript:void(0)">
                                                        <p class="text-light-green"><span
                                                                class="text-dark-white">1</span> Chilli Chicken</p>
                                                        <span class="text-light-white">small, chilli chicken</span>
                                                    </a>
                                                </div>
                                                <div class="delete-btn">
                                                    <a href="javascript:void(0)" class="text-dark-white"> <i
                                                            class="far fa-trash-alt"></i>
                                                    </a>
                                                </div>
                                                <div class="price"> <a href="javascript:void(0)"
                                                        class="text-dark-white fw-500">
                                                        $2.25
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cat-product-box">
                                            <div class="cat-product">
                                                <div class="cat-name">
                                                    <a href="javascript:void(0)">
                                                        <p class="text-light-green"><span
                                                                class="text-dark-white">1</span> loaded cheese</p> <span
                                                            class="text-light-white">small, chilli chicken</span>
                                                    </a>
                                                </div>
                                                <div class="delete-btn">
                                                    <a href="javascript:void(0)" class="text-dark-white"> <i
                                                            class="far fa-trash-alt"></i>
                                                    </a>
                                                </div>
                                                <div class="price"> <a href="javascript:void(0)"
                                                        class="text-dark-white fw-500">
                                                        $2.25
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cat-product-box">
                                            <div class="cat-product">
                                                <div class="cat-name">
                                                    <a href="javascript:void(0)">
                                                        <p class="text-light-green"><span
                                                                class="text-dark-white">1</span> Tortia Chicken</p>
                                                        <span class="text-light-white">small, chilli chicken</span>
                                                    </a>
                                                </div>
                                                <div class="delete-btn">
                                                    <a href="javascript:void(0)" class="text-dark-white"> <i
                                                            class="far fa-trash-alt"></i>
                                                    </a>
                                                </div>
                                                <div class="price"> <a href="javascript:void(0)"
                                                        class="text-dark-white fw-500">
                                                        $2.25
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="item-total">
                                            <div class="total-price border-0"> <span
                                                    class="text-dark-white fw-700">Items subtotal:</span>
                                                <span class="text-dark-white fw-700">$9.99</span>
                                            </div>
                                            <div class="empty-bag padding-15"> <a href="javascript:void(0)">Empty
                                                    bag</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer padding-15"> <a routerLink="/checkout"
                                            class="btn-first green-btn text-custom-white full-width fw-500">Proceed to
                                            Checkout</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- user cart -->
                    </div>
                </div>
                <div class="col-sm-12 mobile-search">
                    <div class="mobile-address">
                        <a href="javascript:void(0)" class="delivery-add" data-toggle="modal"
                            data-target="#address-box"> <span class="address">Brooklyn, NY</span>
                        </a>
                    </div>
                    <div class="sorting-addressbox"> <span class="full-address text-light-green">Brooklyn, NY
                            10041</span>
                        <div class="btns">
                            <div class="filter-btn">
                                <button type="button"><i class="fas fa-sliders-h text-light-green fs-18"></i>
                                </button> <span class="text-light-green">Sort</span>
                            </div>
                            <div class="filter-btn">
                                <button type="button"><i class="fas fa-filter text-light-green fs-18"></i>
                                </button> <span class="text-light-green">Filter</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</div>
<div class="main-sec"></div>
<!-- Navigation -->