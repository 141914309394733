import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import{BasehttpService} from 'src/app/services/basehttp.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BasehttpService{

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  checkUser(data:any){
    return super.callRestAPI('post','/carry/public/index.php/api/check-user', data, false);
  }

  loginUser (data: any) {
    return super.callRestAPI('post','/carry/public/index.php/api/login', data, false);
  }

  getUserDetails() {
    return super.callRestAPI('get','/carry/public/index.php/api/user', {}, true);
  }

  getWalletBalance() {
    return super.callRestAPI('get','/carry/public/index.php/api/user/wallet/balance', {}, true);
  }

  getWalletTransactions() {
    return super.callRestAPI('get','/carry/public/index.php/api/user/wallet/transactions', {}, true);
  }

  getAddresses(){
    return super.callRestAPI('get','/carry/public/index.php/api/user/addresses', {}, true);
  }

  register (data : any) {
    return super.callRestAPI('post','/carry/public/index.php/api/singup',data, false);
  }
}
