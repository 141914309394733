import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app.service';
import { ConstantHelper } from 'src/app/helper/ConstantHelper';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public closeBanner : boolean | undefined;
  categoryList: any;
  constructor(private appService: AppService, private categoryService: CategoryService ) { }
  toggleBanner(){
    this.closeBanner = !this.closeBanner;
  }

  ngOnInit(): void {    
    this.bindData()
    console.log(JSON.parse(localStorage.getItem('userData')));

  }
  bindData() {
    var self = this;
    this.categoryService.getParentCategories({}).subscribe(
      function(response: any) {
        self.categoryList = response.data || null;
      }
    );
  }

  getImage(rData: any){    
    if(rData && typeof(rData.mediaurls) == 'object' && rData.mediaurls.images != undefined && rData.mediaurls.images.length > 0)
      return rData.mediaurls?.images[0]?.default;

    return ConstantHelper.NoImagePath;
  }
}
