import { Address } from "./address";

export class OrderInfo {

    id: number;
    notes?: any;
    meta: OrderMeta;
    subtotal: number;
    taxes: number;
    delivery_fee: number;
    total: number;
    discount: number;
    order_type: string;
    type: string;
    is_guest: number;
    scheduled_on: string;
    status: string;
    vendor_id: number;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    products: OrderProduct[];
    vendor: OrderVendor;
    //user: User3;
    address: Address;
    source_address?: any;
    delivery?: any;
    payment: OrderPayment;
    delivery_mode?: any;
    customer_name?: any;
    customer_email?: any;
    customer_mobile?: any;
}

export interface OrderProduct {
    id: number;
    title: string;
    detail: string;
    meta?: any;
    price: number;
    owner: string;
    parent_id?: any;
    attribute_term_id?: any;
    //mediaurls: Mediaurls4;
    created_at: Date;
    updated_at: Date;
}

export interface PaymentMethod {
    id: number;
    slug: string;
    title: string;
    // title_translations: TitleTranslations5;
    meta?: any;
    enabled: number;
    type: string;
}

export interface OrderPayment {
    id: number;
    payable_id: number;
    payer_id: number;
    amount: number;
    status: string;
    payment_method: PaymentMethod;
}


export interface OrderVendor {
    id: number;
    name: string;
    tagline: string;
    details: string;
    meta?: any;
    //mediaurls: Mediaurls5;
    minimum_order: number;
    delivery_fee: number;
    area: string;
    address: string;
    longitude: number;
    latitude: number;
    is_verified: number;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    // categories: Category2[];
    // product_categories: ProductCategory2[];
    //user: User2;
    ratings: number;
    ratings_count: number;
    favourite_count: number;
    is_favourite: boolean;
    distance?: any;
}

export interface OrderMeta {
    category_id: string;
    category_image: string;
    category_title: string;
}

declare module namespace {

   

    export interface Image {
        default: string;
    }

    export interface Mediaurls {
        images: Image[];
    }

    export interface TitleTranslations {
        en: string;
    }

    export interface Meta2 {
        scope: string;
    }

    export interface Image2 {
        default: string;
        thumb: string;
    }

    export interface Mediaurls2 {
        images: Image2[];
    }

    export interface Category {
        id: number;
        slug: string;
        title: string;
        title_translations: TitleTranslations;
        meta: Meta2;
        sort_order: number;
        mediaurls: Mediaurls2;
        parent_id?: any;
    }

    export interface TitleTranslations2 {
        en: string;
    }

    export interface Meta3 {
        scope: string;
    }

    export interface Image3 {
        default: string;
        thumb: string;
    }

    export interface Mediaurls3 {
        images: Image3[];
    }

    export interface ProductCategory {
        id: number;
        slug: string;
        title: string;
        title_translations: TitleTranslations2;
        meta: Meta3;
        sort_order: number;
        mediaurls: Mediaurls3;
        parent_id?: any;
    }

    export interface User {
        id: number;
        name: string;
        email: string;
        username?: any;
        mobile_number: string;
        mobile_verified: number;
        is_verified: number;
        active: number;
        language: string;
        notification?: any;
        meta?: any;
        remember_token?: any;
        created_at: string;
        updated_at: string;
    }

    export interface Vendor {
        id: number;
        name: string;
        tagline: string;
        details: string;
        meta?: any;
        mediaurls: Mediaurls;
        minimum_order: number;
        delivery_fee: number;
        area: string;
        address: string;
        longitude: number;
        latitude: number;
        is_verified: number;
        user_id: number;
        created_at: Date;
        updated_at: Date;
        categories: Category[];
        product_categories: ProductCategory[];
        user: User;
        ratings: number;
        ratings_count: number;
        favourite_count: number;
        is_favourite: boolean;
        distance?: any;
    }

    export interface Image4 {
        default: string;
    }

    export interface Mediaurls4 {
        images: Image4[];
    }

    

    export interface VendorProduct {
        id: number;
        price: number;
        sale_price?: any;
        sale_price_from?: any;
        sale_price_to?: any;
        stock_quantity: number;
        stock_low_threshold: number;
        product_id: number;
        vendor_id: number;
        vendor: Vendor;
        //product: Product2;
    }

    export interface Product {
        id: number;
        quantity: number;
        total: number;
        subtotal?: any;
        order_id: number;
        vendor_product_id?: any;
        vendor_product: VendorProduct;
        addon_choices: any[];
    }

    export interface Image5 {
        default: string;
    }

    export interface Mediaurls5 {
        images: Image5[];
    }

    export interface TitleTranslations3 {
        en: string;
    }

    export interface Meta4 {
        scope: string;
    }

    export interface Image6 {
        default: string;
        thumb: string;
    }

    export interface Mediaurls6 {
        images: Image6[];
    }

    export interface Category2 {
        id: number;
        slug: string;
        title: string;
        title_translations: TitleTranslations3;
        meta: Meta4;
        sort_order: number;
        mediaurls: Mediaurls6;
        parent_id?: any;
    }

    export interface TitleTranslations4 {
        en: string;
    }

    export interface Meta5 {
        scope: string;
    }

    export interface Image7 {
        default: string;
        thumb: string;
    }

    export interface Mediaurls7 {
        images: Image7[];
    }

    export interface ProductCategory2 {
        id: number;
        slug: string;
        title: string;
        title_translations: TitleTranslations4;
        meta: Meta5;
        sort_order: number;
        mediaurls: Mediaurls7;
        parent_id?: any;
    }

    export interface User2 {
        id: number;
        name: string;
        email: string;
        username?: any;
        mobile_number: string;
        mobile_verified: number;
        is_verified: number;
        active: number;
        language: string;
        notification?: any;
        meta?: any;
        remember_token?: any;
        created_at: string;
        updated_at: string;
    }

    

    export interface Notification {
        customer: string;
    }

    export interface Mediaurls8 {
    }

    export interface Wallet {
        id: number;
        user_id: number;
        balance: number;
        created_at: string;
        updated_at: string;
    }

    export interface User3 {
        id: number;
        name: string;
        email: string;
        username?: any;
        mobile_number: string;
        mobile_verified: number;
        is_verified: number;
        active: number;
        language: string;
        notification: Notification;
        meta?: any;
        mediaurls: Mediaurls8;
        balance: number;
        wallet: Wallet;
        is_following: boolean;
        categories: any[];
        plan?: any;
        is_blocked: boolean;
    }

    export interface Address {
        id: number;
        name?: any;
        email?: any;
        mobile?: any;
        formatted_address: string;
        address1?: any;
        address2?: any;
        longitude: number;
        latitude: number;
        order_id: number;
    }

    export interface TitleTranslations5 {
        en: string;
    }

    
    export interface Datum {
        
    }

    export interface Links {
        first: string;
        last: string;
        prev?: any;
        next?: any;
    }

    export interface Meta6 {
        current_page: number;
        from: number;
        last_page: number;
        path: string;
        per_page: number;
        to: number;
        total: number;
    }

    export interface RootObject {
        data: Datum[];
        links: Links;
        meta: Meta6;
    }

}

