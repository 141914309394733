import { Component, OnInit } from '@angular/core';
import { inject } from '@angular/core/testing';
import { ActivatedRoute } from '@angular/router';
import { List } from 'src/app/helper/util';
import { OrderInfo } from 'src/app/models/order-info';
import { OrderService } from 'src/app/services/order.service';


@Component({
  selector: 'app-orderlist',
  templateUrl: './orderlist.component.html',
  styleUrls: ['./orderlist.component.css']
})
export class OrderlistComponent implements OnInit {
  
  type: string
  orders: List<OrderInfo>
  constructor(private router: ActivatedRoute, private orderService: OrderService) { }

  ngOnInit(): void {
    this.type = this.router.snapshot.params.type;
    this.getOrders();
  }

  getOrders() {

    var self = this;
    self.orders = new List<OrderInfo>();
    this.orderService.getOrderList({}).subscribe(
      function (response: any) {
        if (response && response.data) {
          response.data.forEach(element => {
            debugger;
            let currentOrder = Object.assign(new OrderInfo(), element);
            //self.orders.add(currentOrder);
          });
        }
      },
      function (error) {        
        console.log("Error happened" + error)
      },

    );
  }

}
