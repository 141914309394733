import { Injectable } from '@angular/core';
import { BasehttpService } from './basehttp.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VendorService extends BasehttpService{

  constructor(httpClient: HttpClient) {
    super(httpClient);
   }

  getVendorProducts(data) {
    var url = '/carry/public/index.php/api/products?vendor=' + data.vendorId + '&category=' + data.categoryId; 
    return this.callRestAPI('get', url, {}, false);
   }

   getVendorDetails(data) {
    var url = '/carry/public/index.php/api/vendors/' + data.vendorId; 
    return this.callRestAPI('get', url, {}, false);
   }
}
