<app-navbar></app-navbar>

 <!-- kosher delivery -->
    <section class="section-padding">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="section-header-left">
                        <h3 class="text-light-black header-title title">Kosher Delivery</h3>
                    </div>
                </div>
                <div class="col-12">
                    <div class="kosher-delivery-slider swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="product-box-3">
                                    <div class="product-img">
                                        <a routerLink="/homepage3">
                                            <img src="assets/img/restaurants/604x240/shop-1.jpg" class="img-fluid full-width" alt="#">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="padding-tb-10">
                                            <h5 class="mb-1"><a routerLink="/homepage3" class="text-light-black">Truport (Fillmore)</a></h5>
                                            <ul class="food-type">
                                                <li class="text-light-white">$</li>
                                                <li class="text-light-white">American</li>
                                                <li class="text-light-white">Fast Food</li>
                                                <li class="text-light-white">Burger</li>
                                            </ul>
                                            <ul class="food-description">
                                                <li class="text-light-black">20-30 Min</li>
                                                <li class="text-light-black">4.2 <i class="fas fa-star text-yellow"></i> (200+)</li>
                                                <li class="text-light-black">$2.99 Delivery Fee</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box-3">
                                    <div class="product-img">
                                        <a routerLink="/homepage3">
                                            <img src="assets/img/restaurants/604x240/shop-2.jpg" class="img-fluid full-width" alt="#">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="padding-tb-10">
                                            <h5 class="mb-1"><a routerLink="/homepage3" class="text-light-black">Quickmunch® (Fillmore)</a></h5>
                                            <ul class="food-type">
                                                <li class="text-light-white">$</li>
                                                <li class="text-light-white">American</li>
                                                <li class="text-light-white">Fast Food</li>
                                                <li class="text-light-white">Burger</li>
                                            </ul>
                                            <ul class="food-description">
                                                <li class="text-light-black">20-30 Min</li>
                                                <li class="text-light-black">4.2 <i class="fas fa-star text-yellow"></i> (200+)</li>
                                                <li class="text-light-black">$2.99 Delivery Fee</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box-3">
                                    <div class="product-img">
                                        <a routerLink="/homepage3">
                                            <img src="assets/img/restaurants/604x240/shop-3.jpg" class="img-fluid full-width" alt="#">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="padding-tb-10">
                                            <h5 class="mb-1"><a routerLink="/homepage3" class="text-light-black">Quickmunch® (Fillmore)</a></h5>
                                            <ul class="food-type">
                                                <li class="text-light-white">$</li>
                                                <li class="text-light-white">American</li>
                                                <li class="text-light-white">Fast Food</li>
                                                <li class="text-light-white">Burger</li>
                                            </ul>
                                            <ul class="food-description">
                                                <li class="text-light-black">20-30 Min</li>
                                                <li class="text-light-black">4.2 <i class="fas fa-star text-yellow"></i> (200+)</li>
                                                <li class="text-light-black">$2.99 Delivery Fee</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box-3">
                                    <div class="product-img">
                                        <a routerLink="/homepage3">
                                            <img src="assets/img/restaurants/604x240/shop-4.jpg" class="img-fluid full-width" alt="#">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="padding-tb-10">
                                            <h5 class="mb-1"><a routerLink="/homepage3" class="text-light-black">Quickmunch® (Fillmore)</a></h5>
                                            <ul class="food-type">
                                                <li class="text-light-white">$</li>
                                                <li class="text-light-white">American</li>
                                                <li class="text-light-white">Fast Food</li>
                                                <li class="text-light-white">Burger</li>
                                            </ul>
                                            <ul class="food-description">
                                                <li class="text-light-black">20-30 Min</li>
                                                <li class="text-light-black">4.2 <i class="fas fa-star text-yellow"></i> (200+)</li>
                                                <li class="text-light-black">$2.99 Delivery Fee</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- kosher delivery -->
    <!-- Explore collection -->
    <section class="ex-collection section-padding bg-light-theme home-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="section-header-left">
                      <h6 class="text-light-green sub-title">Delicious Food</h6>
                      <h3 class="text-light-black header-title title">Explore our collections</h3>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="ex-collection-box mb-xl-20">
                                <img src="assets/img/restaurants/604x336/collection-1.jpg" class="img-fluid full-width" alt="image">
                                <div class="category-type overlay padding-15"> <a routerLink="/restaurant" class="category-btn">Top rated</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="ex-collection-box mb-xl-20">
                                <img src="assets/img/restaurants/604x336/collection-2.jpg" class="img-fluid full-width" alt="image">
                                <div class="category-type overlay padding-15"> <a routerLink="/restaurant" class="category-btn">Top rated</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="large-product-box p-relative pb-0">
                                <img src="assets/img/restaurants/287x702/ad-1.jpg" class="img-fluid full-width" alt="image">
                                <div class="category-type overlay padding-15">
                                    <button class="category-btn">Most popular near you</button> <a routerLink="/restaurant" class="btn-first white-btn text-light-black fw-600 full-width">See all</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <div class="row">
                                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                    <div class="product-box mb-xl-20">
                                        <div class="product-img">
                                            <a routerLink="/restaurant">
                                                <img src="assets/img/restaurants/255x150/shop-2.jpg" class="img-fluid full-width" alt="product-img">
                                            </a>
                                            <div class="overlay">
                                                <div class="product-tags padding-10"> <span class="circle-tag">
                            <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                          </span>
                                                    <div class="custom-tag"> <span class="text-custom-white rectangle-tag bg-gradient-red">
                              10%
                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-caption">
                                            <div class="title-box">
                                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black ">Great Burger</a></h6>
                                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-yellow">
                            3.1
                          </span>
                                                </div>
                                            </div>
                                            <p class="text-light-white">American, Fast Food</p>
                                            <div class="product-details">
                                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                    <span class="text-light-white price">$10 min</span>
                                                </div>
                                                <div class="rating"> <span>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                          </span>
                                                    <span class="text-light-white text-right">4225 ratings</span>
                                                </div>
                                            </div>
                                            <div class="product-footer"> <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/004-leaf.svg" alt="tag">
                        </span>
                                                <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/006-chili.svg" alt="tag">
                        </span>
                                                <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/005-chef.svg" alt="tag">
                        </span>
                                                <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/008-protein.svg" alt="tag">
                        </span>
                                                <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/009-lemon.svg" alt="tag">
                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                    <div class="product-box mb-xl-20">
                                        <div class="product-img">
                                            <a routerLink="/restaurant">
                                                <img src="assets/img/restaurants/255x150/shop-3.jpg" class="img-fluid full-width" alt="product-img">
                                            </a>
                                            <div class="overlay">
                                                <div class="product-tags padding-10"> <span class="circle-tag">
                            <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-caption">
                                            <div class="title-box">
                                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Flavor Town</a></h6>
                                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-red">
                            2.1
                          </span>
                                                </div>
                                            </div>
                                            <p class="text-light-white">Breakfast, Lunch & Dinner</p>
                                            <div class="product-details">
                                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                    <span class="text-light-white price">$10 min</span>
                                                </div>
                                                <div class="rating"> <span>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                          </span>
                                                    <span class="text-light-white text-right">4225 ratings</span>
                                                </div>
                                            </div>
                                            <div class="product-footer"> <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/007-chili-1.svg" alt="tag">
                        </span>
                                                <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/004-leaf.svg" alt="tag">
                        </span>
                                                <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/009-lemon.svg" alt="tag">
                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                    <div class="product-box mb-xl-20">
                                        <div class="product-img">
                                            <a routerLink="/restaurant">
                                                <img src="assets/img/restaurants/255x150/shop-4.jpg" class="img-fluid full-width" alt="product-img">
                                            </a>
                                            <div class="overlay">
                                                <div class="product-tags padding-10"> <span class="circle-tag">
                            <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                          </span>
                                                    <span class="type-tag bg-gradient-green text-custom-white">
                            Trending
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-caption">
                                            <div class="title-box">
                                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Big Bites</a></h6>
                                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-green">
                            4.5
                          </span>
                                                </div>
                                            </div>
                                            <p class="text-light-white">Pizzas, Fast Food</p>
                                            <div class="product-details">
                                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                    <span class="text-light-white price">$10 min</span>
                                                </div>
                                                <div class="rating"> <span>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                          </span>
                                                    <span class="text-light-white text-right">4225 ratings</span>
                                                </div>
                                            </div>
                                            <div class="product-footer"> <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/005-chef.svg" alt="tag">
                        </span>
                                                <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/004-leaf.svg" alt="tag">
                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                    <div class="product-box mb-xs-20">
                                        <div class="product-img">
                                            <a routerLink="/restaurant">
                                                <img src="assets/img/restaurants/255x150/shop-5.jpg" class="img-fluid full-width" alt="product-img">
                                            </a>
                                            <div class="overlay">
                                                <div class="product-tags padding-10"> <span class="circle-tag">
                            <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                          </span>
                                                    <span class="type-tag bg-gradient-green text-custom-white">
                            Trending
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-caption">
                                            <div class="title-box">
                                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Smile N’ Delight</a></h6>
                                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-green">
                            4.5
                          </span>
                                                </div>
                                            </div>
                                            <p class="text-light-white">Desserts, Beverages</p>
                                            <div class="product-details">
                                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                    <span class="text-light-white price">$10 min</span>
                                                </div>
                                                <div class="rating"> <span>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                          </span>
                                                    <span class="text-light-white text-right">4225 ratings</span>
                                                </div>
                                            </div>
                                            <div class="product-footer"> <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/005-chef.svg" alt="tag">
                        </span>
                                                <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/008-protein.svg" alt="tag">
                        </span>
                                                <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/009-lemon.svg" alt="tag">
                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                    <div class="product-box mb-md-20">
                                        <div class="product-img">
                                            <a routerLink="/restaurant">
                                                <img src="assets/img/restaurants/255x150/shop-6.jpg" class="img-fluid full-width" alt="product-img">
                                            </a>
                                            <div class="overlay">
                                                <div class="product-tags padding-10"> <span class="circle-tag">
                            <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                          </span>
                                                    <div class="custom-tag"> <span class="text-custom-white rectangle-tag bg-gradient-red">
                              20%
                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-caption">
                                            <div class="title-box">
                                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black ">Lil Johnny’s</a></h6>
                                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-red">
                            2.1
                          </span>
                                                </div>
                                            </div>
                                            <p class="text-light-white">Continental & Mexican</p>
                                            <div class="product-details">
                                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                    <span class="text-light-white price">$10 min</span>
                                                </div>
                                                <div class="rating"> <span>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                          </span>
                                                    <span class="text-light-white text-right">4225 ratings</span>
                                                </div>
                                            </div>
                                            <div class="product-footer"> <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/008-protein.svg" alt="tag">
                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                    <div class="product-box mb-md-20">
                                        <div class="product-img">
                                            <a routerLink="/restaurant">
                                                <img src="assets/img/restaurants/255x150/shop-7.jpg" class="img-fluid full-width" alt="product-img">
                                            </a>
                                            <div class="overlay">
                                                <div class="product-tags padding-10"> <span class="circle-tag">
                            <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                          </span>
                                                    <span class="text-custom-white type-tag bg-gradient-orange">
                            NEW
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product-caption">
                                            <div class="title-box">
                                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black ">Choice Foods</a></h6>
                                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-green">
                            4.5
                          </span>
                                                </div>
                                            </div>
                                            <p class="text-light-white">Indian, Chinese, Tandoor</p>
                                            <div class="product-details">
                                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                    <span class="text-light-white price">$10 min</span>
                                                </div>
                                                <div class="rating"> <span>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                            <i class="fas fa-star text-yellow"></i>
                          </span>
                                                    <span class="text-light-white text-right">4225 ratings</span>
                                                </div>
                                            </div>
                                            <div class="product-footer"> <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/005-chef.svg" alt="tag">
                        </span>
                                                <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/008-protein.svg" alt="tag">
                        </span>
                                                <span class="text-custom-white square-tag">
                          <img src="assets/img/svg/009-lemon.svg" alt="tag">
                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="advertisement-slider swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="large-product-box p-relative pb-0">
                                    <img src="assets/img/sidebanner-1.jpg" class="img-fluid full-width" alt="image">
                                    <div class="overlay padding-20">
                                        <div class="tag-box">
                                            <span class="text-custom-white rectangle-tag bg-gradient-red">Trending</span>
                                        </div>
                                        <div class="content-wrapper">
                                            <h3 class="text-custom-white">50% Discount on All New Restaurants</h3>
                                            <a routerLink="/homepage3" class="btn-submit btn-second">Get Deals</a>
                                        </div>
                                    </div>
                                    <div class="overlay overlay-bg"></div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="large-product-box p-relative pb-0">
                                    <img src="assets/img/sidebanner-2.jpg" class="img-fluid full-width" alt="image">
                                    <div class="overlay padding-20">
                                        <div class="tag-box">
                                            <span class="text-custom-white rectangle-tag bg-gradient-red">New</span>
                                        </div>
                                        <div class="content-wrapper">
                                            <h3 class="text-custom-white">50% Discount on All New Restaurants</h3>
                                            <a routerLink="/homepage3" class="btn-submit btn-second">Get Deals</a>
                                        </div>
                                    </div>
                                    <div class="overlay overlay-bg"></div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="large-product-box p-relative pb-0">
                                    <img src="assets/img/sidebanner-3.jpg" class="img-fluid full-width" alt="image">
                                    <div class="overlay padding-20">
                                        <div class="tag-box">
                                            <span class="text-custom-white rectangle-tag bg-gradient-red">Trending</span>
                                        </div>
                                        <div class="content-wrapper">
                                            <h3 class="text-custom-white">50% Discount on All New Restaurants</h3>
                                            <a routerLink="/homepage3" class="btn-submit btn-second">Get Deals</a>
                                        </div>
                                    </div>
                                    <div class="overlay overlay-bg"></div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="large-product-box p-relative pb-0">
                                    <img src="assets/img/sidebanner-2.jpg" class="img-fluid full-width" alt="image">
                                    <div class="overlay padding-20">
                                        <div class="tag-box">
                                            <span class="text-custom-white rectangle-tag bg-gradient-red">New</span>
                                        </div>
                                        <div class="content-wrapper">
                                            <h3 class="text-custom-white">50% Discount on All New Restaurants</h3>
                                            <a routerLink="/homepage3" class="btn-submit btn-second">Get Deals</a>
                                        </div>
                                    </div>
                                    <div class="overlay overlay-bg"></div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Explore collection -->
    <!-- kosher delivery -->
    <section class="section-padding">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="section-header-left">
                      <h6 class="text-light-green sub-title">Feel of Taste</h6>
                        <h3 class="text-light-black header-title title">Specialized Food</h3>
                    </div>
                </div>
                <div class="col-12">
                    <div class="kosher-delivery-slider swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="product-box-3">
                                    <div class="product-img">
                                        <a routerLink="/homepage3">
                                            <img src="assets/img/restaurants/604x240/shop-5.jpg" class="img-fluid full-width" alt="#">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="padding-tb-10">
                                            <h5 class="mb-1"><a routerLink="/homepage3" class="text-light-black">Quickmunch® (Fillmore)</a></h5>
                                            <ul class="food-type">
                                                <li class="text-light-white">$</li>
                                                <li class="text-light-white">American</li>
                                                <li class="text-light-white">Fast Food</li>
                                                <li class="text-light-white">Burger</li>
                                            </ul>
                                            <ul class="food-description">
                                                <li class="text-light-black">20-30 Min</li>
                                                <li class="text-light-black">4.2 <i class="fas fa-star text-yellow"></i> (200+)</li>
                                                <li class="text-light-black">$2.99 Delivery Fee</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box-3">
                                    <div class="product-img">
                                        <a routerLink="/homepage3">
                                            <img src="assets/img/restaurants/604x240/shop-6.jpg" class="img-fluid full-width" alt="#">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="padding-tb-10">
                                            <h5 class="mb-1"><a routerLink="/homepage3" class="text-light-black">Quickmunch® (Fillmore)</a></h5>
                                            <ul class="food-type">
                                                <li class="text-light-white">$</li>
                                                <li class="text-light-white">American</li>
                                                <li class="text-light-white">Fast Food</li>
                                                <li class="text-light-white">Burger</li>
                                            </ul>
                                            <ul class="food-description">
                                                <li class="text-light-black">20-30 Min</li>
                                                <li class="text-light-black">4.2 <i class="fas fa-star text-yellow"></i> (200+)</li>
                                                <li class="text-light-black">$2.99 Delivery Fee</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box-3">
                                    <div class="product-img">
                                        <a routerLink="/homepage3">
                                            <img src="assets/img/restaurants/604x240/shop-7.jpg" class="img-fluid full-width" alt="#">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="padding-tb-10">
                                            <h5 class="mb-1"><a routerLink="/homepage3" class="text-light-black">Quickmunch® (Fillmore)</a></h5>
                                            <ul class="food-type">
                                                <li class="text-light-white">$</li>
                                                <li class="text-light-white">American</li>
                                                <li class="text-light-white">Fast Food</li>
                                                <li class="text-light-white">Burger</li>
                                            </ul>
                                            <ul class="food-description">
                                                <li class="text-light-black">20-30 Min</li>
                                                <li class="text-light-black">4.2 <i class="fas fa-star text-yellow"></i> (200+)</li>
                                                <li class="text-light-black">$2.99 Delivery Fee</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box-3">
                                    <div class="product-img">
                                        <a routerLink="/homepage3">
                                            <img src="assets/img/restaurants/604x240/shop-8.jpg" class="img-fluid full-width" alt="#">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="padding-tb-10">
                                            <h5 class="mb-1"><a routerLink="/homepage3" class="text-light-black">Quickmunch® (Fillmore)</a></h5>
                                            <ul class="food-type">
                                                <li class="text-light-white">$</li>
                                                <li class="text-light-white">American</li>
                                                <li class="text-light-white">Fast Food</li>
                                                <li class="text-light-white">Burger</li>
                                            </ul>
                                            <ul class="food-description">
                                                <li class="text-light-black">20-30 Min</li>
                                                <li class="text-light-black">4.2 <i class="fas fa-star text-yellow"></i> (200+)</li>
                                                <li class="text-light-black">$2.99 Delivery Fee</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- kosher delivery -->
    <!-- kosher delivery -->
    <section class="section-padding">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="section-header-left">
                      <h6 class="text-light-green sub-title">Fresh Ingredients</h6>
                        <h3 class="text-light-black header-title title">Chef Special</h3>
                    </div>
                </div>
                <div class="col-12">
                    <div class="kosher-delivery-slider swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="product-box-3">
                                    <div class="product-img">
                                        <a routerLink="/homepage3">
                                            <img src="assets/img/restaurants/604x240/shop-9.jpg" class="img-fluid full-width" alt="#">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="padding-tb-10">
                                            <h5 class="mb-1"><a routerLink="/homepage3" class="text-light-black">Quickmunch® (Fillmore)</a></h5>
                                            <ul class="food-type">
                                                <li class="text-light-white">$</li>
                                                <li class="text-light-white">American</li>
                                                <li class="text-light-white">Fast Food</li>
                                                <li class="text-light-white">Burger</li>
                                            </ul>
                                            <ul class="food-description">
                                                <li class="text-light-black">20-30 Min</li>
                                                <li class="text-light-black">4.2 <i class="fas fa-star text-yellow"></i> (200+)</li>
                                                <li class="text-light-black">$2.99 Delivery Fee</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box-3">
                                    <div class="product-img">
                                        <a routerLink="/homepage3">
                                            <img src="assets/img/restaurants/604x240/shop-10.jpg" class="img-fluid full-width" alt="#">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="padding-tb-10">
                                            <h5 class="mb-1"><a routerLink="/homepage3" class="text-light-black">Quickmunch® (Fillmore)</a></h5>
                                            <ul class="food-type">
                                                <li class="text-light-white">$</li>
                                                <li class="text-light-white">American</li>
                                                <li class="text-light-white">Fast Food</li>
                                                <li class="text-light-white">Burger</li>
                                            </ul>
                                            <ul class="food-description">
                                                <li class="text-light-black">20-30 Min</li>
                                                <li class="text-light-black">4.2 <i class="fas fa-star text-yellow"></i> (200+)</li>
                                                <li class="text-light-black">$2.99 Delivery Fee</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box-3">
                                    <div class="product-img">
                                        <a routerLink="/homepage3">
                                            <img src="assets/img/restaurants/604x240/shop-1.jpg" class="img-fluid full-width" alt="#">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="padding-tb-10">
                                            <h5 class="mb-1"><a routerLink="/homepage3" class="text-light-black">Quickmunch® (Fillmore)</a></h5>
                                            <ul class="food-type">
                                                <li class="text-light-white">$</li>
                                                <li class="text-light-white">American</li>
                                                <li class="text-light-white">Fast Food</li>
                                                <li class="text-light-white">Burger</li>
                                            </ul>
                                            <ul class="food-description">
                                                <li class="text-light-black">20-30 Min</li>
                                                <li class="text-light-black">4.2 <i class="fas fa-star text-yellow"></i> (200+)</li>
                                                <li class="text-light-black">$2.99 Delivery Fee</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box-3">
                                    <div class="product-img">
                                        <a routerLink="/homepage3">
                                            <img src="assets/img/restaurants/604x240/shop-3.jpg" class="img-fluid full-width" alt="#">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="padding-tb-10">
                                            <h5 class="mb-1"><a routerLink="/homepage3" class="text-light-black">Quickmunch® (Fillmore)</a></h5>
                                            <ul class="food-type">
                                                <li class="text-light-white">$</li>
                                                <li class="text-light-white">American</li>
                                                <li class="text-light-white">Fast Food</li>
                                                <li class="text-light-white">Burger</li>
                                            </ul>
                                            <ul class="food-description">
                                                <li class="text-light-black">20-30 Min</li>
                                                <li class="text-light-black">4.2 <i class="fas fa-star text-yellow"></i> (200+)</li>
                                                <li class="text-light-black">$2.99 Delivery Fee</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- kosher delivery -->
    <!-- Browse by category -->
    <section class="browse-cat section-padding">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="section-header-left">
                        <h3 class="text-light-black header-title title">Food Near Me <span class="fs-14"><a routerLink="/restaurant">View All</a></span></h3>
                    </div>
                </div>
                <div class="col-12">
                    <div class="food-near-me swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <a routerLink="/restaurant" class="categories">
                                    <div class="icon icon2 text-custom-white bg-light-green ">
                                        <img src="assets/img/restaurants/220x220/nearme-1.jpg" class="rounded-circle" alt="categories">
                                    </div> <span class="text-light-black cat-name">Chicken Special Pizza</span>
                                </a>
                            </div>
                            <div class="swiper-slide">
                                <a routerLink="/restaurant" class="categories">
                                    <div class="icon icon2 text-custom-white bg-light-green ">
                                        <img src="assets/img/restaurants/220x220/nearme-2.jpg" class="rounded-circle" alt="categories">
                                    </div> <span class="text-light-black cat-name">Hakka Noodles</span>
                                </a>
                            </div>
                            <div class="swiper-slide">
                                <a routerLink="/restaurant" class="categories">
                                    <div class="icon icon2 text-custom-white bg-light-green ">
                                        <img src="assets/img/restaurants/220x220/nearme-3.jpg" class="rounded-circle" alt="categories">
                                    </div> <span class="text-light-black cat-name">Vegan Burger</span>
                                </a>
                            </div>
                            <div class="swiper-slide">
                                <a routerLink="/restaurant" class="categories">
                                    <div class="icon icon2 text-custom-white bg-light-green ">
                                        <img src="assets/img/restaurants/220x220/nearme-4.jpg" class="rounded-circle" alt="categories">
                                    </div> <span class="text-light-black cat-name">Sticky Date Cake</span>
                                </a>
                            </div>
                            <div class="swiper-slide">
                                <a routerLink="/restaurant" class="categories">
                                    <div class="icon icon2 text-custom-white bg-light-green ">
                                        <img src="assets/img/restaurants/220x220/nearme-5.jpg" class="rounded-circle" alt="categories">
                                    </div> <span class="text-light-black cat-name">Thai Dumpling Soup</span>
                                </a>
                            </div>
                            <div class="swiper-slide">
                                <a routerLink="/restaurant" class="categories">
                                    <div class="icon icon2 text-custom-white bg-light-green ">
                                        <img src="assets/img/restaurants/220x220/nearme-6.jpg" class="rounded-circle" alt="categories">
                                    </div> <span class="text-light-black cat-name">American Chop Suey</span>
                                </a>
                            </div>
                            <div class="swiper-slide">
                                <a routerLink="/restaurant" class="categories">
                                    <div class="icon icon2 text-custom-white bg-light-green ">
                                        <img src="assets/img/restaurants/220x220/nearme-7.jpg" class="rounded-circle" alt="categories">
                                    </div> <span class="text-light-black cat-name">
                    Italian Sausage Pasta </span>
                                </a>
                            </div>
                            <div class="swiper-slide">
                                <a routerLink="/restaurant" class="categories">
                                    <div class="icon icon2 text-custom-white bg-light-green ">
                                        <img src="assets/img/restaurants/220x220/nearme-8.jpg" class="rounded-circle" alt="categories">
                                    </div> <span class="text-light-black cat-name">Spaghetti</span>
                                </a>
                            </div>
                            <div class="swiper-slide">
                                <a routerLink="/restaurant" class="categories">
                                    <div class="icon icon2 text-custom-white bg-light-green ">
                                        <img src="assets/img/restaurants/220x220/nearme-2.jpg" class="rounded-circle" alt="categories">
                                    </div> <span class="text-light-black cat-name">Mexican Street Tacos</span>
                                </a>
                            </div>
                        </div>
                        <!-- Add Arrows -->
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Browse by category -->

    <app-footer2></app-footer2>