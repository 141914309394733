<app-navbar></app-navbar>

<!-- restaurent top -->
    <div class="page-banner p-relative smoothscroll" id="menu">
        <img src="assets/img/banner.jpg" class="img-fluid full-width" alt="banner">
        <div class="overlay-2">
            <div class="container">
                <div class="row">
                    <div class="col-6">
                        <div class="back-btn">
                            <button type="button" class="text-light-green"> <i class="fas fa-chevron-left"></i>
              </button>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="tag-share"> <span class="text-light-green share-tag">
                <i class="fas fa-chevron-right"></i>
              </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- restaurent top -->
    <!-- restaurent details -->
    <section class="restaurent-details  u-line">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="heading padding-tb-10">
                        <h3 class="text-light-black title fw-700 no-margin">{{pageDetail.title}}</h3>
                        <p class="text-light-black sub-title no-margin" *ngIf="pageDetail.shortDesciption">{{pageDetail.shortDesciption}}<span>                            
                        </span>
                        </p>
                        <div class="head-rating">
                            <div class="rating">
                                
                <ngb-rating [max]="5" [(rate)]="pageDetail.ratings" [readonly]="true"></ngb-rating>
                                <!-- <span class="text-light-black fs-12 rate-data">{{pageDetail.ratings}}</span> -->
                            </div>
                            <div class="product-review">
                                <div class="restaurent-details-mob">
                                    <a routerLink="/restaurant-style-1"> <span class="text-light-black"><i class="fas fa-info-circle"></i></span>
                                        <span class="text-dark-white">info</span>
                                    </a>
                                </div>
                                <div class="restaurent-details-mob">
                                    <a routerLink="/restaurant-style-1"> <span class="text-light-black"><i class="fas fa-info-circle"></i></span>
                                        <span class="text-dark-white">info</span>
                                    </a>
                                </div>
                                <div class="restaurent-details-mob">
                                    <a routerLink="/restaurant-style-1"> <span class="text-light-black"><i class="fas fa-info-circle"></i></span>
                                        <span class="text-dark-white">info</span>
                                    </a>
                                </div>
                                <div class="restaurent-details-mob">
                                    <a routerLink="/restaurant-style-1"> <span class="text-light-black"><i class="fas fa-info-circle"></i></span>
                                        <span class="text-dark-white">info</span>
                                    </a>
                                </div>                               
                            </div>
                        </div>
                    </div>
                    <div class="restaurent-logo">
                        <img [src]="pageDetail.pageDefaultImage"  class="img-fluid" alt="#">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- restaurent details -->
    <!-- restaurent tab -->
    <div class="restaurent-tabs u-line">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="restaurent-menu scrollnav">
                        <ul class="nav nav-pills">
                            <li class="nav-item"> <a class="nav-link active text-light-white fw-700" data-toggle="pill" href="#menu">Menu</a>
                            </li>
                            <li class="nav-item"> <a class="nav-link text-light-white fw-700" data-toggle="pill" href="#about">About</a>
                            </li>
                            <li class="nav-item"> <a class="nav-link text-light-white fw-700" data-toggle="pill" href="#review">Reviews</a>
                            </li>
                            <li class="nav-item"> <a class="nav-link text-light-white fw-700" data-toggle="pill" href="#mapgallery">Map & Gallery</a>
                            </li>
                        </ul>
                        <div class="add-wishlist">
                            <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- restaurent tab -->
    <!-- restaurent address -->
    <div class="restaurent-address u-line">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="address-details">
                        <div class="address">
                            <div class="delivery-address"> <a routerLink="/orderdetails" class="text-light-black">Delivery, ASAP (45–55m)</a>
                                <div class="delivery-type"> <span class="text-success fs-12 fw-500">{{(vendorDetail.minimum_order > 0 ? vendorDetail.minimum_order : 'No')}} minimun</span><span class="text-light-white"></span>
                                </div>
                            </div>
                            <!-- <div class="change-address"> <a routerLink="/checkout" class="fw-500">Change</a>
                            </div> -->
                        </div>
                        <!-- <p class="text-light-white no-margin">Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- restaurent address -->
    <!-- restaurent meals -->
    <section class="section-padding restaurent-meals bg-light-theme">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-3 col-lg-3">
                  <app-restaurantleftsidebar></app-restaurantleftsidebar>  
                </div>
                <div class="col-xl-6 col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="promocodeimg mb-xl-20 p-relative">
                                <img src="assets/img/banner-2.jpg" class="img-fluid full-width" alt="promocode">
                                <div class="promocode-text">
                                    <div class="promocode-text-content">
                                        <h5 class="text-custom-white mb-2 fw-600">Get $10 off your first order!</h5>
                                        <p class="text-custom-white no-margin">Spend $15 or more and get $10 off your first delivery order.</p>
                                    </div>
                                    <div class="promocode-btn"> <a routerLink="/ex-deals">Get Deal</a>
                                    </div>
                                </div>
                                <div class="overlay overlay-bg"></div>
                            </div>
                        </div>
                        <div class="col-lg-12 restaurent-meal-head mb-md-40">
                            <div class="card">
                                <div class="card-header">
                                    <div class="section-header-left">
                                        <h3 class="text-light-black header-title">
                                            <a class="card-link text-light-black no-margin  after-none" routerLink="/restaurant-style-1">
                                Most Popular
                              </a>
                                        </h3>
                                    </div>
                                </div>
                                <div id="collapseOne" class="collapse show">
                                    <div class="card-body no-padding">
                                        <div class="row">
                                            <div class="col-lg-12" *ngFor="let data of dataList;let i = index" [attr.data-index]="i">
                                                <div class="restaurent-product-list">
                                                    <div class="restaurent-product-detail">
                                                        <div class="restaurent-product-left">
                                                            <div class="restaurent-product-title-box">
                                                                <div class="restaurent-product-box">
                                                                    <div class="restaurent-product-title">
                                                                        <h6 class="mb-2" data-toggle="modal" data-target="#restaurent-popup"><a href="javascript:void(0)" class="text-light-black fw-600">{{data.title}}</a></h6>
                                                                        <p class="text-light-white">{{data.tagline}}</p>
                                                                    </div>                                                                    
                                                                </div>
                                                                <div class="restaurent-product-rating">
                                                                    <div>
                                                                        <ngb-rating [max]="5" [(rate)]="data.ratings" [readonly]="false"></ngb-rating>
                                                                    </div>
                                                                    <div class="rating-text">
                                                                        <p class="text-light-white fs-12 title">{{data.ratings_count}} ratings</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="restaurent-product-caption-box"> <span class="text-light-white">{{data.details}}</span> 
                                                            </div>
                                                            <div class="restaurent-tags-price">
                                                                <div class="restaurent-tags">
                                                                     <span class="text-custom-white square-tag">
                                    <img src="assets/img/svg/004-leaf.svg" alt="tag">
                                  </span>
                                                                    <span class="text-custom-white square-tag">
                                    <img src="assets/img/svg/005-chef.svg" alt="tag">
                                  </span>
                                                                    <span class="text-custom-white square-tag">
                                    <img src="assets/img/svg/008-protein.svg" alt="tag">
                                  </span>
                                                                    <span class="text-custom-white square-tag">
                                    <img src="assets/img/svg/009-lemon.svg" alt="tag">
                                  </span>
                                                                </div> 
                                                                <span class="circle-tag">
                                  <img src="assets/img/svg/010-heart.svg" alt="tag">
                                </span>
                                                                <div class="restaurent-product-price">
                                                                    <h6 class="text-success fw-600 no-margin">{{data.price}} ₹</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="restaurent-product-img">
                                                            <img [src]="getImage(data)" class="img-fluid" [alt]="data.title">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-3">
                    <app-restaurantrightsidebar></app-restaurantrightsidebar> 
                </div>
            </div>
        </div>
    </section>
    <!-- restaurent meals -->
    <!-- restaurent about -->
    <section class="section-padding restaurent-about smoothscroll" id="about">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="text-light-black fw-700 title">{{pageDetail.title}} Info</h3>
                    <p class="text-light-white no-margin">{{vendorDetail.details}}</p> 
                    
                    <ul class="about-restaurent">
                        <li> <i class="fas fa-map-marker-alt"></i>
                            <span>
                <a  class="text-light-white">
                    {{venderAddress.address}}
                </a>
              </span>
                        </li>
                        <li> <i class="fas fa-phone-alt"></i>
                            <span><a href="tel:{{vendorDetail.phone}}" class="text-light-white">{{vendorDetail.phone}}</a></span>
                        </li>
                        <li> <i class="far fa-envelope"></i>
                            <span><a href="mailto:{{vendorDetail.email}}" class="text-light-white">{{vendorDetail.email}}</a></span>
                        </li>
                    </ul>
                    <!-- <ul class="social-media pt-2">
                        <li> <a routerLink="/restaurant-style-1"><i class="fab fa-facebook-f"></i></a>
                        </li>
                        <li> <a routerLink="/restaurant-style-1"><i class="fab fa-twitter"></i></a>
                        </li>
                        <li> <a routerLink="/restaurant-style-1"><i class="fab fa-instagram"></i></a>
                        </li>
                        <li> <a routerLink="/restaurant-style-1"><i class="fab fa-pinterest-p"></i></a>
                        </li>
                        <li> <a routerLink="/restaurant-style-1"><i class="fab fa-youtube"></i></a>
                        </li>
                    </ul> -->
                </div>
                <!-- <div class="col-md-6">
                    <div class="restaurent-schdule">
                        <div class="card">
                            <div class="card-header text-light-white fw-700 fs-16">Hours</div>
                                                        <div class="card-body">
                                <div class="schedule-box">
                                    <div class="day text-light-black">Monday</div>
                                    <div class="time text-light-black">Delivery: 7:00am - 10:59pm</div>
                                </div>
                                <div class="collapse" id="schdule">
                                    <div class="schedule-box">
                                        <div class="day text-light-black">Tuesday</div>
                                        <div class="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                                    </div>
                                    <div class="schedule-box">
                                        <div class="day text-light-black">Wednesday</div>
                                        <div class="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                                    </div>
                                    <div class="schedule-box">
                                        <div class="day text-light-black">Thursday</div>
                                        <div class="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                                    </div>
                                    <div class="schedule-box">
                                        <div class="day text-light-black">Friday</div>
                                        <div class="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                                    </div>
									    <div class="schedule-box">
                                        <div class="day text-light-black">Saturday</div>
                                        <div class="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                                    </div>
                                        <div class="schedule-box">
                                        <div class="day text-light-black">Sunday</div>
                                        <div class="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer"> <a class="fw-500 collapsed" data-toggle="collapse" href="#schdule">See the full schedule</a>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
    <!-- restaurent about -->
    <!-- map gallery -->
    <div class="map-gallery-sec section-padding bg-light-theme smoothscroll" id="mapgallery">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="main-box">
                        <div class="row">
                            <div class="col-md-6 map-pr-0">
                                <iframe id="locmap" src="https://maps.google.com/maps?q={{venderAddress.address}}&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
                            </div>
                            <!-- <div class="col-md-6 map-pl-0">
                                <div class="gallery-box padding-10">
                                    <ul class="gallery-img">
                                        <li>
                                            <a class="image-popup" href="assets/img/gallery/img-1.jpg" title="Image title">
                                                <img src="assets/img/gallery/img-1.jpg" class="img-fluid full-width" alt="9.jpg" />
                                            </a>
                                        </li>
                                        <li>
                                            <a class="image-popup" href="assets/img/gallery/img-2.jpg" title="Image title">
                                                <img src="assets/img/gallery/img-2.jpg" class="img-fluid full-width" alt="9.jpg" />
                                            </a>
                                        </li>
                                        <li>
                                            <a class="image-popup" href="assets/img/gallery/img-3.jpg" title="Image title">
                                                <img src="assets/img/gallery/img-3.jpg" class="img-fluid full-width" alt="9.jpg" />
                                            </a>
                                        </li>
                                        <li>
                                            <a class="image-popup" href="assets/img/gallery/img-4.jpg" title="Image title">
                                                <img src="assets/img/gallery/img-4.jpg" class="img-fluid full-width" alt="9.jpg" />
                                            </a>
                                        </li>
                                        <li>
                                            <a class="image-popup" href="assets/img/gallery/img-5.jpg" title="Image title">
                                                <img src="assets/img/gallery/img-5.jpg" class="img-fluid full-width" alt="9.jpg" />
                                            </a>
                                        </li>
                                        <li>
                                            <a class="image-popup" href="assets/img/gallery/img-6.jpg" title="Image title">
                                                <img src="assets/img/gallery/img-6.jpg" class="img-fluid full-width" alt="9.jpg" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- map gallery -->
    <!-- restaurent reviews -->
    <section class="section-padding restaurent-review smoothscroll" id="review">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-header-left">
                        <h3 class="text-light-black header-title title">Reviews for Great Burger</h3>
                    </div>
                    <div class="restaurent-rating mb-xl-20">
                        <div class="star"> <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-dark-white fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-dark-white fs-16">
                <i class="fas fa-star"></i>
              </span>
                        </div> <span class="fs-12 text-light-black">58 Ratings</span>
                    </div>
                    <p class="text-light-black mb-xl-20">Here's what people are saying:</p>
                    <ul>
                        <li>
                            <h6 class="text-light-black mb-1">73%</h6>
                            <span class="text-light-black fs-12 fw-400">Food was good</span>
                        </li>
                        <li>
                            <h6 class="text-light-black mb-1">85%</h6>
                            <span class="text-light-black fs-12 fw-400">Delivery was on time</span>
                        </li>
                        <li>
                            <h6 class="text-light-black mb-1">68%</h6>
                            <span class="text-light-black fs-12 fw-400">Order was accurate</span>
                        </li>
                    </ul>
                    <div class="u-line"></div>
                </div>
                <div class="col-md-12">
                    <div class="review-box">
                        <div class="review-user">
                            <div class="review-user-img">
                                <img src="assets/img/blog-details/40x40/user-2.png" class="rounded-circle" alt="#">
                                <div class="reviewer-name">
                                    <p class="text-light-black fw-600">Sarra <small class="text-light-white fw-500">New York, (NY)</small>
                                    </p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">Top Reviewer</span>
                                </div>
                            </div>
                            <div class="review-date"> <span class="text-light-white">Sep 20, 2022</span>
                            </div>
                        </div>
                        <div class="ratings"> <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="ml-2 text-light-white">2 days ago</span>
                        </div>
                        <p class="text-light-black">Delivery was fast and friendly. Food was not great especially the salad. Will not be ordering from again. Too many options to settle for this place.</p> <span class="text-light-white fs-12 food-order">Kathy ordered:</span>
                        <ul class="food">
                            <li>
                                <button class="add-pro bg-gradient-red">Coffee <span class="close">+</span>
                </button>
                            </li>
                            <li>
                                <button class="add-pro bg-dark">Pizza <span class="close">+</span>
                </button>
                            </li>
                            <li>
                                <button class="add-pro bg-gradient-green">Noodles <span class="close">+</span>
                </button>
                            </li>
                            <li>
                                <button class="add-pro bg-gradient-orange">Burger <span class="close">+</span>
                </button>
                            </li>
                        </ul>
                    </div>
                    <div class="review-box">
                        <div class="review-user">
                            <div class="review-user-img">
                                <img src="assets/img/blog-details/40x40/user-3.png" class="rounded-circle" alt="#">
                                <div class="reviewer-name">
                                    <p class="text-light-black fw-600">Sarra <small class="text-light-white fw-500">New York, (NY)</small>
                                    </p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">Top Reviewer</span>
                                </div>
                            </div>
                            <div class="review-date"> <span class="text-light-white">Sep 20, 2022</span>
                            </div>
                        </div>
                        <div class="ratings"> <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="ml-2 text-light-white">2 days ago</span>
                        </div>
                        <p class="text-light-black">Delivery was fast and friendly. Food was not great especially the salad. Will not be ordering from again. Too many options to settle for this place.</p> <span class="text-light-white fs-12 food-order">Kathy ordered:</span>
                        <ul class="food">
                            <li>
                                <button class="add-pro bg-gradient-red">Coffee <span class="close">+</span>
            </button>
                            </li>
                            <li>
                                <button class="add-pro bg-dark">Pizza <span class="close">+</span>
            </button>
                            </li>
                            <li>
                                <button class="add-pro bg-gradient-green">Noodles <span class="close">+</span>
            </button>
                            </li>
                            <li>
                                <button class="add-pro bg-gradient-orange">Burger <span class="close">+</span>
            </button>
                            </li>
                        </ul>
                    </div>
                    <div class="review-box">
                        <div class="review-user">
                            <div class="review-user-img">
                                <img src="assets/img/blog-details/40x40/user-4.png" class="rounded-circle" alt="#">
                                <div class="reviewer-name">
                                    <p class="text-light-black fw-600">Sarra <small class="text-light-white fw-500">New York, (NY)</small>
                                    </p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">Top Reviewer</span>
                                </div>
                            </div>
                            <div class="review-date"> <span class="text-light-white">Sep 20, 2022</span>
                            </div>
                        </div>
                        <div class="ratings"> <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="text-yellow fs-16">
                <i class="fas fa-star"></i>
              </span>
                            <span class="ml-2 text-light-white">2 days ago </span>
                        </div>
                        <p class="text-light-black">Delivery was fast and friendly. Food was not great especially the salad. Will not be ordering from again. Too many options to settle for this place.</p> <span class="text-light-white fs-12 food-order">Kathy ordered:</span>
                        <ul class="food">
                            <li>
                                <button class="add-pro bg-gradient-red">Coffee <span class="close">+</span>
            </button>
                            </li>
                            <li>
                                <button class="add-pro bg-dark">Pizza <span class="close">+</span>
            </button>
                            </li>
                            <li>
                                <button class="add-pro bg-gradient-green">Noodles <span class="close">+</span>
            </button>
                            </li>
                            <li>
                                <button class="add-pro bg-gradient-orange">Burger <span class="close">+</span>
            </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12">
                    <div class="review-img">
                        <img src="assets/img/review-footer.png" class="img-fluid" alt="#">
                        <div class="review-text">
                            <h2 class="text-light-white mb-2 fw-600">Be one of the first to review</h2>
                            <p class="text-light-white">Order now and write a review to give others the inside scoop.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- restaurent reviews -->
    <!-- offer near -->
    <!-- <section class="fresh-deals section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-header-left">
                        <h3 class="text-light-black header-title title">Offers near you</h3>
                    </div>
                </div>
                <div class="col-12">
                    <div class="fresh-deals-slider swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="product-box">
                                    <div class="product-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/restaurants/255x150/shop-10.jpg" class="img-fluid full-width" alt="product-img">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="title-box">
                                            <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Great Burger</a></h6>
                                        </div>
                                        <p class="text-light-white">American, Fast Food</p>
                                        <div class="product-details">
                                            <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                <span class="text-light-white price">$10 min</span>
                                            </div>
                                            <div class="rating"> <span>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                        </span>
                                                <span class="text-light-white text-right">4225 ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-footer-2">
                                        <div class="discount"> <span class="text-success fs-12">$3 off</span>
                                        </div>
                                        <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box">
                                    <div class="product-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/restaurants/255x150/shop-12.jpg" class="img-fluid full-width" alt="product-img">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="title-box">
                                            <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Flavor Town</a></h6>
                                        </div>
                                        <p class="text-light-white">Breakfast, Lunch & Dinner</p>
                                        <div class="product-details">
                                            <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                <span class="text-light-white price">$10 min</span>
                                            </div>
                                            <div class="rating"> <span>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                        </span>
                                                <span class="text-light-white text-right">4225 ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-footer-2">
                                        <div class="discount"> <span class="text-success fs-12">$3 off</span>
                                        </div>
                                        <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box">
                                    <div class="product-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/restaurants/255x150/shop-17.jpg" class="img-fluid full-width" alt="product-img">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="title-box">
                                            <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Big Bites</a></h6>
                                        </div>
                                        <p class="text-light-white">Pizzas, Fast Food</p>
                                        <div class="product-details">
                                            <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                <span class="text-light-white price">$10 min</span>
                                            </div>
                                            <div class="rating"> <span>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                        </span>
                                                <span class="text-light-white text-right">4225 ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-footer-2">
                                        <div class="discount"> <span class="text-success fs-12">$3 off</span>
                                        </div>
                                        <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box">
                                    <div class="product-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/restaurants/255x150/shop-10.jpg" class="img-fluid full-width" alt="product-img">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="title-box">
                                            <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Smile N’ Delight</a></h6>
                                        </div>
                                        <p class="text-light-white">Desserts, Beverages</p>
                                        <div class="product-details">
                                            <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                <span class="text-light-white price">$10 min</span>
                                            </div>
                                            <div class="rating"> <span>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                        </span>
                                                <span class="text-light-white text-right">4225 ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-footer-2">
                                        <div class="discount"> <span class="text-success fs-12">$3 off</span>
                                        </div>
                                        <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box">
                                    <div class="product-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/restaurants/255x150/shop-32.jpg" class="img-fluid full-width" alt="product-img">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="title-box">
                                            <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Lil Johnny’s</a></h6>
                                        </div>
                                        <p class="text-light-white">Continental & Mexican</p>
                                        <div class="product-details">
                                            <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                <span class="text-light-white price">$10 min</span>
                                            </div>
                                            <div class="rating"> <span>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                        </span>
                                                <span class="text-light-white text-right">4225 ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-footer-2">
                                        <div class="discount"> <span class="text-success fs-12">$3 off</span>
                                        </div>
                                        <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
    <!-- offer near -->

    <app-footer2></app-footer2>