import { Injectable } from '@angular/core';
import { BasehttpService } from './basehttp.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private baseHttpService: BasehttpService) { }

  getOrderList(data: any){
    return this.baseHttpService.callRestAPI('get','carry/public/index.php/api/orders',data, true);
  }

  getOrderDetails(data: any){
    return this.baseHttpService.callRestAPI('get','carry/public/index.php/api/orders', data, true);
  }
}
