import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BasehttpService } from './basehttp.service'; 
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends BasehttpService{

  ListCategories : any;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getParentCategories(data: any): Observable<any> {
    return this.callRestAPI('get','/carry/public/index.php/api/categories?parent=1&scope=ecommerce', data, false)
    .pipe(map((response: any)=>{
      if (response) {
        this.ListCategories = response;
        }
        return this.ListCategories;
      })
    );   
  }

  ///carry/public/index.php/api/vendors/list?category=' + query +'&long=77.35004909999998&lat=11.1030294&page=1&per_page=15
  getSubCategories(data: any): Observable<any> {
    return this.callRestAPI('get', '/carry/public/index.php/api/vendors/list?category=' + data.Id + '&long=' + data.Long + '&lat=' + data.Lang + '&page=' + data.PageNo + '&per_page=' + data.PageSize, data, false)
    .pipe(map((response: any)=>{
      if (response) {
        this.ListCategories = response;
        }
        return this.ListCategories;
      })
    );   
  }
}
