import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  categoryId: string;
  state$: Observable<object>;
  data:any
  subCategoryList:any

  constructor(public activatedRoute: ActivatedRoute, private categoryService: CategoryService) {}

  ngOnInit() {
    this.activatedRoute.paramMap
      .pipe(map(() => {
       
      

      }))
    this.data = window.history.state
      
    this.categoryId = this.activatedRoute.snapshot.params.id;
    this.bindData();
  }


  bindData() {
    var self = this;
    this.categoryService.getSubCategories({
      Id: Number(this.categoryId),
      Long:77.35004909999998,
      Lang: 11.1030294,
      PageNo: 1,
      PageSize: 15
    }).subscribe(function(response){
      if(response){
        self.subCategoryList = response.data;
      }
    });

  }
}
