import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { VendorService } from 'src/app/services/vendor.service';
import { PageDetails } from 'src/app/models/pagedetails';
import { Address } from 'src/app/models/address';
import { ConstantHelper } from 'src/app/helper/ConstantHelper';
@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.css']
})
export class VendorComponent implements OnInit {
  state$: Observable<object>;
  data: any
  dataList: any
  vendorId: string
  categoryId: string
  pageDetail: PageDetails
  venderAddress: Address
  vendorDetail: any

  constructor(public activatedRoute: ActivatedRoute, private vendorService: VendorService) {   
  }

  ngOnInit() {
    this.pageDetail = new PageDetails();
    this.venderAddress = new Address();
    this.vendorDetail = {
      email: '',
      phone: '',
      details: ''
    };
    this.data = window.history.state;
    this.vendorId = this.activatedRoute.snapshot.params.id;
    this.categoryId = this.activatedRoute.snapshot.params.categoryid;
    this.getVenderDetails();
    this.bindProducts();
  }

  // bind the products
  bindProducts() {
    var self = this;
    this.vendorService.getVendorProducts({
      categoryId: this.categoryId,
      vendorId: this.vendorId
    }).subscribe(
      function (response: any) {
        if (response) {
          self.dataList = response.data;
        }
      },
      function (error) {
        console.log("Error happened" + error)
      },
    );
  }

  getVenderDetails(){
    var self = this;
    this.vendorService.getVendorDetails({      
      vendorId: this.vendorId
    }).subscribe(
      function (response: any) {
        if (response) {
            self.pageDetail = new PageDetails();             
            self.pageDetail.title = response.name;
            self.pageDetail.shortDesciption = response.tagline;
            self.pageDetail.ratings = response.ratings;
            self.pageDetail.pageDefaultImage = response.mediaurls && response.mediaurls.images != undefined ? response.mediaurls.images[0].default : '';           

            self.venderAddress.address = response.address;
            self.venderAddress.area = response.area;
            self.venderAddress.latitude = response.latitude;
            self.venderAddress.longitude = response.longitude;

            if (response.user){
              self.vendorDetail.email = response.user.email;
              self.vendorDetail.phone = response.user.mobile_number;
              self.vendorDetail.details = response.details;
              self.vendorDetail.minimum_order = response.minimum_order || 0;
            }            
        }
      },
      function (error) {
        console.log("Error happened" + error)
      },
    );
  }

  getImage(rData: any){
    if(rData && typeof(rData.mediaurls) == 'object' && rData.mediaurls.images != undefined && rData.mediaurls.images.length > 0)
      return rData.mediaurls?.images[0]?.default;

      return ConstantHelper.NoImagePath;
  }
}