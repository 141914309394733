import { Component, OnInit } from '@angular/core';
import firebase from 'firebase';
import { WindowService } from 'src/window.service';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  user: any = {
    name: "",
    mobile_number: "",
    email: ""
  }

  windowRef: any;
  phoneNumber: any
  verificationCode: string;
  checkData: any;
  isExist: any = true;
  isOTPElement: boolean = false;
  isCaptchaVerified: boolean = false;

  constructor(private win: WindowService, private router: Router, private userService: UserService) { }

  ngOnInit() {

    var self = this;
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    } else {
      firebase.app(); // if already initialized, use that one
    }

    self.windowRef = this.win.windowRef;
    if (!this.isOTPElement) {
      setTimeout(function () {
        self.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container',
          {
            'callback': function(response) {
              // reCAPTCHA solved - will proceed with submit function
              self.isCaptchaVerified = true;              
            },  
            'expired-callback': function() {
              // Reset reCAPTCHA?
            }
          }
        )
        self.windowRef.recaptchaVerifier.render()
      }, 100);
    }
  }


  checkUserExist(data){    
    var self = this;    
    var response = this.userService.checkUser({
      "mobile_number": '+91' + data.mobile_number,
      "role": "customer"    
    }).subscribe(
      function (response: any) {
        if (response) {
          if (response.message == "User exists") {
              alert("User already exist.");
          } else {
            self.register(data) 
          }
        }
      },
      function (error) {        
        if (error.status == 422) {
          self.register(data) 
        }
      },
    );
  }
  
  register(data) {
    const appVerifier = this.windowRef.recaptchaVerifier;
    var self = this;
    data.role = 'customer';
    data.mobile_number = '+91' + data.mobile_number;
  
    this.userService.register(data).subscribe(         
      function (response: any) {        
        if (response) {
          self.checkData = response;
          if (self.checkData.token) {
            self.isExist = true
            self.sendLoginCode(data.mobile_number)
          }
        }
      },
      function (error) {
        console.log("status" + error.status)
        if (error.status == 422) {
          self.router.navigate(['/register']);
        }
        console.log("Error happened" + error)
      },
    );
  }

  sendLoginCode(number) {

    const appVerifier = this.windowRef.recaptchaVerifier;
    const num = number;
    firebase.auth().signInWithPhoneNumber(num, appVerifier)
      .then(result => {
        this.windowRef.confirmationResult = result;
        this.isOTPElement = true;
      })
      .catch(error => console.log(error));
  }

  verifyLoginCode() {
    this.windowRef.confirmationResult
      .confirm(this.verificationCode)
      .then(result => {
        this.user = result.user;
        this.login(this.user.Aa);
      })
      .catch(error => console.log(error, "Incorrect code entered?"));
  }

  login(token) {
    var self = this;
    var data = {
      "token": token,
      "role": "customer"
    }
    this.userService.loginUser(data).subscribe(
      function (response: any) {
        if (response) {
          localStorage.setItem('userData', JSON.stringify(response));
          localStorage.setItem('token', response.token);
          self.router.navigate(['/']);
        }
      },
      function (error) {
        console.log("status" + error.status)
        if (error.status == 422) {
          self.router.navigate(['/register']);
        }
        console.log("Error happened" + error);
      },
    );
  }
}
