 <!-- advertisement banner-->
            <div class="row">
                <div class="col-12">
                    <div class="banner-adv2 mb-xl-20">
                        <img src="assets/img/restaurants/1110x100/vbanner-3.jpg" class="img-fluid full-width" alt="banner"> <span class="text">Unlimited Free Delivery with. <img src="assets/img/tag.jpg" alt="logo"> 
                            <a routerLink="/restaurant" class="btn-second btn-submit">Try 30 Days FREE</a></span>
                        <span class="close-banner"></span>
                        <div class="overlay overlay-bg"></div>
                    </div>
                </div>
            </div>
            <!-- advertisement banner-->