export class SessionHelper{

    static IsLoginUser(){
        return (localStorage.getItem('token') && localStorage.getItem('token') != null) || false;
    }

    static GetLoginUserData(){
        return JSON.parse(localStorage.getItem('userData'));
    }

    static RemoveUserToken(){
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
    }

    static isAuthenticated(){
        return this.IsLoginUser();
    }

}