import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map} from "rxjs/operators";
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { ApiConfig } from '../configs/api-config';
// import { LocalStorage } from '../configs/local-storage';

@Injectable({
	providedIn: 'root'
})
export class BasehttpService {
	//public singleresult: T;
    endpoint: string = environment.apiEndPoint;
	constructor(private http: HttpClient) {
	}

	/**
   * Calling API
   * @param set_method method name
   * @param set_URL method url
   * @param set_Data method data
   */
	callRestAPI(set_method: any, set_URL: any, set_Data: any, userAuthentication: any = false): Observable<any> {
		set_URL = this.endpoint + set_URL;
		let token = localStorage.getItem('token');
		if (set_method == "get") {

			if (userAuthentication === true) {
				//For API with bearer
				return this.http.get(set_URL, {
					headers: { Authorization: "Bearer " + token }
				}).pipe(
					map((res: any) => {
						return (res ? res : null);
					}));
				;
			} else {
				return this.http.get(set_URL).pipe(
					map((res: any) => {
						return (res ? res : null);
					}));
				;
			}			
		} else if (set_method == "post") {
			if (userAuthentication === true) {
				//For API with bearer
				return this.http.post(set_URL, set_Data, {
					headers: { Authorization: "Bearer " + token }
				}).pipe(
					map((res: any) => {
						return (res ? res : null);
					}));
				;
			}
			else {
				//For Login authentication API without bearer
				return this.http.post<any>(set_URL, set_Data)
				.pipe(
					map((res: any) => {			
						return (res ? res : null);
					}));
			}
		} else if (set_method == "delete") {
			return this.http.delete(set_URL, {
				headers: { Authorization: "Bearer " + token }
			}).pipe(
				map((res: any) => {
					return (res ? res : null);
				}));
		}
	}
}
