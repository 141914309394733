import { Component, OnInit } from '@angular/core';
import { PhoneNumber } from './phonenumber';
import firebase from 'firebase';
import { WindowService } from 'src/window.service';
import { environment } from 'src/environments/environment.prod';
import { AppService } from 'src/app.service';
import { UserService } from 'src/app/services/user.service';

import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  windowRef: any;
  phoneNumber: any
  verificationCode: string;
  user: any;
  checkData: any;
  isExist: any = true;
  isOTPElement: boolean = false;
  isCaptchaVerified: boolean = false;

  constructor(private win: WindowService, private appService: AppService, private router: Router, private userService: UserService) { }

  ngOnInit() {
    
    var self = this;
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    } else {
      firebase.app(); // if already initialized, use that one
    }

    self.windowRef = this.win.windowRef;
    if (!this.isOTPElement) {
      setTimeout(function () {
        self.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container',
          {
            'callback': function(response) {
              // reCAPTCHA solved - will proceed with submit function
              self.isCaptchaVerified = true;              
            },  
            'expired-callback': function() {
              // Reset reCAPTCHA?
            }
          }
        )
        self.windowRef.recaptchaVerifier.render()
      }, 100);
    }   
  }

  check(number) {
    const appVerifier = this.windowRef.recaptchaVerifier;
    var self = this;    
    number = '+91' + number;
    var data = {
      "mobile_number": number,
      "role": "customer"
    }
    var response = this.userService.checkUser(data).subscribe(
      function (response: any) {
        if (response) {
          if (response.message == "User exists") {
            self.isExist = true
            self.sendLoginCode(number)
          }
        }
      },
      function (error) {
        console.log("status" + error.status)
        if (error.status == 422) {
          self.router.navigate(['/register']);
        }
        console.log("Error happened" + error)

      },
    );

  }
  login(token) {
    var self = this;
    var data = {
      "token": token,
      "role": "customer"
    }
    this.userService.loginUser(data).subscribe(
      function (response: any) {
        if (response) {
          localStorage.setItem('userData', JSON.stringify(response));
          localStorage.setItem('token', response.token);
          self.router.navigate(['/']);
        }
      },
      function (error) {
        console.log("status" + error.status)
        if (error.status == 422) {
          self.router.navigate(['/register']);
        }
        console.log("Error happened" + error)

      },

    );

  }
  userData(token) {
    var self = this;
    var data = {
      "token": token,
      "role": "customer"
    }
    this.userService.loginUser(data).subscribe(
      function (response: any) {
        if (response) {
          localStorage.setItem('userData', response);
          localStorage.setItem('token', response.token);
          self.router.navigate(['/home']);
        }
      },
      function (error) {
        console.log("status" + error.status)
        if (error.status == 422) {
          self.router.navigate(['/register']);
        }
        console.log("Error happened" + error)

      },

    );

  }
  sendLoginCode(number) {

    const appVerifier = this.windowRef.recaptchaVerifier;    
    const num = number;
    debugger;
    firebase.auth().signInWithPhoneNumber(num, appVerifier)
      .then(result => {
        this.windowRef.confirmationResult = result;
        this.isOTPElement = true;
      })
      .catch(error => console.log(error));

  }

  verifyLoginCode() {
    this.windowRef.confirmationResult
      .confirm(this.verificationCode)
      .then(result => {

        this.user = result.user;
        console.log(this.user)
        this.login(this.user.Aa)


      })
      .catch(error => console.log(error, "Incorrect code entered?"));
  }


}