export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyApAZYJ37ckvwlcp2sBG9dSUHl80SzoXcI",
    authDomain: "carry-31d4b.firebaseapp.com",
    projectId: "carry-31d4b",
    storageBucket: "carry-31d4b.appspot.com",
    messagingSenderId: "358209484946",
    appId: "1:358209484946:web:3ca434b21011adc362ce4b",
    measurementId: "G-R9YZTGTKY8"
  },
  apiEndPoint: "https://carry.truport.in"
};
