import { Injectable } from "@angular/core";

@Injectable()
export class List<T> {
    private items: Array<T>;
  
    constructor() {      
        this.items = [];
    }
  
    size(): number {
        return this.items.length;
    }
  
    add(value: T): void {
        this.items.push(value);
    }
  
    get(index: number): T {
        return this.items[index];
    }
}


// class ListItem<T> {
//     private list: List<T>;
//     private index: number;

//     public value: T;

//     constructor(list: List<T>, value: T, index: number) {
//         this.list = list;
//         this.index = index;
//         this.value = value;
//     }

//     prev(): ListItem<T> {
//         return this.list.get(this.index - 1);
//     }

//     next(): ListItem<T> {
//         return this.list.get(this.index + 1);   
//     }
// }

// class List<T> {
//     private items: Array<ListItem<T>>;

//     constructor() {
//         this.items = [];
//     }

//     size(): number {
//         return this.items.length;
//     }

//     add(value: T): void {
//         this.items.push(new ListItem<T>(this, value, this.size()));
//     }

//     get(index: number): ListItem<T> {
//         return this.items[index];
//     }
// }