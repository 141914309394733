<div class="inner-wrapper">
    <div class="container-fluid no-padding">
      <div class="row no-gutters overflow-auto">
        <div class="col-md-6">
          <div class="main-banner">
            <!-- <img src="assets/img/banner/banner-1.jpg" class="img-fluid full-width main-img" alt="banner">
            <div class="overlay-2 main-padding">
              <img src="assets/img/logo-2.jpg" class="img-fluid" alt="logo">
            </div>
            <img src="assets/img/banner/burger.png" class="footer-img" alt="footer-img"> -->
          </div>
        </div>
        <div class="col-md-6">
          <div class="section-2 user-page main-padding">
            <div class="login-sec">
              <div class="login-box">
                <form>
                  <h4 class="text-light-black fw-600">Create your account</h4>
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12"  *ngIf="!isOTPElement">
                      <div class="form-group">
                        <label class="text-light-white fs-14">Full name</label>
                        <input type="text" [(ngModel)]="user.name" name="#" class="form-control form-control-submit" placeholder="Full Name" required>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12"  *ngIf="!isOTPElement">
                      <div class="form-group">
                        <label class="text-light-white fs-14">Mobile</label>                      
                        <input type="text" [(ngModel)]="user.mobile_number" name="#" maxlength="10" class="form-control form-control-submit has-prefix" placeholder="Mobile without +91" required>                                                                  
                      </div>                      
                    </div>
                    <div class="col-12">
                      <div class="form-group"  *ngIf="!isOTPElement">
                        <label class="text-light-white fs-14">Email</label>
                        <input type="email"[(ngModel)]="user.email" name="#" class="form-control form-control-submit" placeholder="Email I'd" required>
                      </div>
                      <div class="form-group" *ngIf="isOTPElement">
                        <label class="text-light-white fs-14">OTP</label>
                        <input type="password" [(ngModel)]="verificationCode" id="password-field" name="#" class="form-control form-control-submit" value="password" placeholder="Enter OTP" required>
                        <div data-name="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></div>
                      </div>
                    
                      <div *ngIf="!isOTPElement" id="recaptcha-container"></div>

                      <div class="form-group" *ngIf="!isOTPElement">
                        <button type="submit" class="btn-second btn-submit full-width" [disabled] = "!isCaptchaVerified" (click)="checkUserExist(user)">
                          <img src="assets/img/M.png" alt="btn logo">Send OTP</button>
                      </div>
                      <div class="form-group" *ngIf="isOTPElement">
                        <button type="submit" class="btn-second btn-submit full-width" (click)="verifyLoginCode()">
                          <img src="assets/img/M.png" alt="btn logo">Verify</button>
                      </div>
                      <div class="form-group text-center"> <span>or</span>
                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn-second btn-facebook full-width">
                          <img src="assets/img/facebook-logo.svg" alt="btn logo">Continue with Facebook</button>
                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn-second btn-google full-width">
                          <img src="assets/img/google-logo.png" alt="btn logo">Continue with Google</button>
                      </div>
                      <div class="form-group text-center">
                        <p class="text-light-black mb-0">Have an account? <a routerLink="/login">Sign in</a>
                        </p>
                      </div> <span class="text-light-black fs-12 terms">By creating your Truport account, you agree to the <a routerLink="/privacy"> Terms of Use </a> and <a routerLink="/privacy"> Privacy Policy.</a></span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>