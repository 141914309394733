import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { SessionHelper } from '../helper/sessionhelper';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //return this.authService.isAuthenticated();
    if (SessionHelper.isAuthenticated()) {
      return true;
    }  
    else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
